<template>
  <v-container>
    <v-container>
      <span class="text-h5 font-weight-bold">
        {{ $t('pages.TargetSetting.title') }}
      </span>
    </v-container>
    <!-- TODO: titleとstepper部分はコンポーネントに切り出す（atoms/stepper作成後） -->
    <v-container>
      <span class="text-h6 font-weight-bold">{{ $t('target.title.kpi') }}</span>
    </v-container>
    <v-layout justify-center>
      <v-card width="100%">
        <stepper v-slot="{ step, setStep }">
          <step :i="1" :step="step" :title="$t('target.step.kpi.target_select')">
            <template #content><TargetSelect v-model:target="targetKpi" /></template>
            <template #nav>
              <v-btn color="primary" @click="() => setStep(2)">
                {{ $t('common.button.next') }}
              </v-btn>
            </template>
          </step>
          <step :i="2" :step="step" :title="$t('target.step.kpi.kpi_index_setting')">
            <template #content>
              <Suspense>
                <TargetKpi v-model:kpi-items="kpiItems" v-model:refetch="refetch" :target="targetKpi" />
              </Suspense>
            </template>
            <template #nav>
              <v-btn color="primary" @click="() => setStep(1)">
                {{ $t('common.button.prev') }}
              </v-btn>
              <v-btn color="primary" class="ml-2" @click="isShowRegister = true">
                {{ $t('common.button.register') }}
              </v-btn>
              <Suspense>
                <TargetKpiRegister
                  v-model:isShow="isShowRegister"
                  :target="targetKpi"
                  :kpi-items="kpiItems"
                  @on-success="refetch && refetch()"
                />
              </Suspense>
            </template>
          </step>
        </stepper>
      </v-card>
    </v-layout>
    <v-divider class="my-5"></v-divider>
    <v-container>
      <span class="text-h6 font-weight-bold">{{ $t('target.title.target_value') }}</span>
    </v-container>
    <v-layout justify-center>
      <v-card width="100%">
        <v-tabs v-model="activeSettingType" grow>
          <v-tab v-for="type in TARGET_SETTING_TYPE" :key="type" :value="type" color="primary">
            {{ $t(`pages.TargetSetting.settingType.${type}`) }}
          </v-tab>
        </v-tabs>
        <v-window v-model="activeSettingType">
          <v-window-item :value="TARGET_SETTING_TYPE.MANUAL">
            <target-manual-setting
              v-model:message="message"
              :setting-type="activeSettingType"
              :add-target="addTarget"
              :divide-target="divideTarget"
              :get-target-settings-total="getTargetSettingsTotal"
              :get-this-month-range="getThisMonthRange"
            ></target-manual-setting>
          </v-window-item>
          <v-window-item :value="TARGET_SETTING_TYPE.BULK">
            <target-bulk-setting
              v-model:message="message"
              :setting-type="activeSettingType"
              :add-target="addTarget"
              :divide-target="divideTarget"
              :get-target-settings-total="getTargetSettingsTotal"
              :get-this-month-range="getThisMonthRange"
            ></target-bulk-setting>
          </v-window-item>
        </v-window>
        <v-snackbar
          v-model="isShowMessage"
          location="top right"
          :timeout="message.timeout"
          :color="message.type"
          @update:model-value="clearMessage()"
        >
          {{ message.text }}
        </v-snackbar>
      </v-card>
    </v-layout>
  </v-container>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import TargetManualSetting from '@/components/targetSetting/TargetManualSetting.vue'
import TargetBulkSetting from '@/components/targetSetting/TargetBulkSetting.vue'
import { TargetMediaSetting } from '@/vuex/modules/target-media-settings'
import { TargetCategorySetting } from '@/vuex/modules/target-category-settings'
import { TargetAllSetting, TargetValues } from '@/vuex/modules/target-all-settings'
import { TARGET_SETTING_TARGET, TARGET_SETTING_TYPE } from '@/constants/constant'
import { add, divide } from '@/utils/numeral'
import { MessageItemsState } from '@/vuex/modules/message'
import DateUtils, { compareDate } from '@/utils/date'
import { RangeDate } from '@/components/datePicker/DatePicker.vue'
import Stepper from '@/components/stepper/Stepper.vue'
import Step from '@/components/stepper/Step.vue'
import TargetSelect from '@/components/organisms/TargetSelect/TargetSelect.vue'
import TargetKpi from '@/components/organisms/TargetKpi/TargetKpi.vue'
import TargetKpiRegister from '@/components/organisms/TargetKpiRegister/TargetKpiRegister.vue'
import type { Target } from '@/domain/model/target/types'
import type { KpiAllSetting, KpiCategorySetting, KpiMediaSetting } from '@/domain/model/target/types'
import type { Refetch } from '@/components/hooks/query'

export type valueOf<T> = T[keyof T]
export type TargetSettingType = valueOf<typeof TARGET_SETTING_TYPE>
export type TargetType = valueOf<typeof TARGET_SETTING_TARGET>
export type FixTargetMediaSetting = Omit<TargetMediaSetting, 'report_date'> & {
  is_active: boolean
}

export type FixTargetCategorySetting = Omit<TargetCategorySetting, 'report_date'> & {
  group_name: string
  is_active: boolean
}

export type FixTargetAllSetting = Omit<TargetAllSetting, 'report_date'> & {
  is_active: boolean
}

export interface addTarget {
  (augend?: Partial<TargetValues>, addend?: Partial<TargetValues>): TargetValues
}
export interface divideTarget {
  (dividend: Partial<TargetValues>, divisor: number): TargetValues
}

export interface getTargetSettingsTotal {
  (settings: Partial<TargetValues>[]): TargetValues
}

export interface getThisMonthRange {
  (day: number): RangeDate
}

export type KpiItems = Partial<
  Record<'all', KpiAllSetting[]> &
    Record<'media', ({ isActive: boolean } & KpiMediaSetting)[]> &
    Record<'category', ({ isActive: boolean } & KpiCategorySetting)[]>
>

export default defineComponent({
  name: 'TargetSetting',
  components: {
    TargetManualSetting,
    TargetBulkSetting,
    Stepper,
    Step,
    TargetSelect,
    TargetKpi,
    TargetKpiRegister
  },
  setup() {
    const isShowMessage = ref<boolean>(false)
    const activeSettingType = ref<TargetSettingType>(TARGET_SETTING_TYPE.MANUAL)
    const message = ref<MessageItemsState>({ text: '' })

    const targetKpi = ref<Target>('all')
    const kpiItems = ref<KpiItems>({ all: [], media: [], category: [] })
    const isShowRegister = ref(false)
    const refetch = ref<Refetch | null>(null)

    const addTarget = (augend?: Partial<TargetValues>, addend?: Partial<TargetValues>): TargetValues =>
      Object.fromEntries(
        [...Array(20).keys()]
          .map(i => i + 1)
          .map(i => [
            `target_value${i}`,
            add(augend?.[`target_value${i}`] ?? 0, addend?.[`target_value${i}`] ?? 0) || null
          ])
      )

    const divideTarget = (dividend: Partial<TargetValues>, divisor: number): TargetValues =>
      Object.fromEntries(
        [...Array(20).keys()]
          .map(i => i + 1)
          .map(i => [`target_value${i}`, divide(dividend[`target_value${i}`], divisor)])
      )

    const getTargetSettingsTotal = (settings: Partial<TargetValues>[]): TargetValues =>
      settings.reduce(
        (acc, cur) => ({
          ...addTarget(acc, cur)
        }),
        { ...addTarget() }
      )

    const clearMessage = () => {
      if (!isShowMessage.value) message.value = { text: '' }
    }

    const getThisMonthRange = (day: number): RangeDate => {
      const nowDate = new DateUtils()
      const baseDate = new DateUtils().day(day)
      const defaultDate = compareDate(baseDate.format(), nowDate.format()) ? baseDate : baseDate.beforeMonths(1)
      return {
        from: defaultDate.format(),
        to: defaultDate.afterMonths(1).beforeDays(1).format()
      }
    }

    watch(
      () => message.value.text,
      v => {
        isShowMessage.value = !!v
      }
    )

    return {
      TARGET_SETTING_TYPE,
      isShowMessage,
      activeSettingType,
      message,
      targetKpi,
      kpiItems,
      isShowRegister,
      refetch,
      addTarget,
      divideTarget,
      getTargetSettingsTotal,
      clearMessage,
      getThisMonthRange
    }
  }
})
</script>
