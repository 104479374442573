<template>
  <Card variant="flat" color="light-grey">
    <RadioGroup :model-value="target" inline :options="options" @update:model-value="v => $emit('update:target', v)" />
    <!-- TODO: molecules/DateRangePicker作成後、propsを変更する -->
    <!-- TODO: molecules/DateRangePicker作成後、@update:model-valueの型エラー直す -->
    <DateRangePicker
      v-if="!!dates"
      :range-date="dates"
      :input-props="{
        variant: 'solo',
        flat: true,
        density: 'comfortable',
        'hide-details': true,
        'append-icon': 'mdi-menu-down',
        style: { width: '310px' }
      }"
      :presets="presets"
      @update:model-value="v => $emit('update:dates', v)"
    />
  </Card>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import Card from '@/components/atoms/Card/Card.vue'
import RadioGroup, { type RadioOption } from '@/components/atoms/RadioGroup/RadioGroup.vue'
import type { RangeDate } from '@/components/datePicker/DatePicker.vue' // TODO: atoms/DatePickerに移行
import DateRangePicker from '@/components/datePicker/DateRangePicker.vue' // TODO: molecules/DateRangePickerに移行
import { TargetLabel } from '@/domain/model/target/constants'
import type { Target } from '@/domain/model/target/types'
import DateUtils from '@/utils/date'

type Props = {
  target: Target
  dates?: RangeDate
}

defineProps<Props>()

const { t } = useI18n()

const options: RadioOption<Target>[] = Object.entries(TargetLabel).map(([k, v]) => ({ label: v, value: k as Target }))

// TODO: presetsの実装はここではなく、DateRangePickerに持ちたい
const presets = computed(() => [
  {
    label: `${t('Period.thisMonth')}`,
    range: [new DateUtils().firstDay().format(), new DateUtils().lastDay().format()] as const
  },
  {
    label: `${t('Period.nextMonth')}`,
    range: [
      new DateUtils().afterMonths(1).firstDay().format(),
      new DateUtils().afterMonths(1).lastDay().format()
    ] as const
  }
])

defineEmits(['update:target', 'update:dates'])
</script>
