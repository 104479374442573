import i18n from '@/i18n/i18n'

import type { KpiCriteria, Target } from '@/domain/model/target/types'

const TargetEnum = {
  ALL: 'all',
  MEDIA: 'media',
  CATEGORY: 'category'
} as const

export const TargetLabel = {
  [TargetEnum.ALL]: i18n.global.t('target.target_select.all'),
  [TargetEnum.MEDIA]: i18n.global.t('target.target_select.media'),
  [TargetEnum.CATEGORY]: i18n.global.t('target.target_select.category')
} as const satisfies Record<Target, string>

const KpiCriteriaEnum = {
  CPA: 'cpa',
  ROAS: 'roas'
} as const

export const KpiCriteriaLabel = {
  [KpiCriteriaEnum.CPA]: i18n.global.t('target.kpi_index_setting.criteria_cpa'),
  [KpiCriteriaEnum.ROAS]: i18n.global.t('target.kpi_index_setting.criteria_roas')
} as const satisfies Record<KpiCriteria, string>
