import { createI18n } from 'vue-i18n'

export default createI18n({
  legacy: false,
  locale: 'ja-JP',
  silentTranslationWarn: true,
  messages: {
    'en-US': {
      integration_report: {
        step: {
          report_template: 'Format setting'
        },
        report_template: {
          label_horizontal: 'Standard',
          label_vertical: 'Vertical'
        }
      },
      // TODO: 以下のi18nの書き方統一させる
      charts: {
        CompareChart: {
          Total: 'Total',
          Estimation: 'Estimation',
          '7daysAverage': '7daysAverage'
        },
        TargetChart: {
          TargetIsNotSet: 'Target is not set'
        }
      },
      globals: {
        push: {
          allowPushText1: 'Allow push notifications and get immediate results',
          allowPushText2: '　',
          allowPushText3: 'For example:',
          allowPushText4: 'When raw data download is completed'
        },
        notices: {
          Notice: {
            download: 'Download',
            blank: 'No data available'
          },
          NoticeMenu: {
            notification: 'Notice'
          }
        },
        settings: {
          users: {
            AddDialog: {
              addNewMember: 'Add new user',
              mailAddress: 'mail address',
              notRegisteredMessage: 'The email address domain is not registered.',
              usableDomainMessage: 'The domain of the email address that can be added is {domain} .',
              employeeNumber: 'Employee Number',
              grantOwnership: 'Grant ownership',
              yes: 'Add',
              no: 'Cancel'
            },
            AddApiUserDialog: {
              addNewMember: 'Add new API user',
              mailAddress: 'mail address',
              grantOwnership: 'Grant ownership',
              yes: 'Add',
              no: 'Cancel'
            },
            DeleteDialog: {
              deleteMember: 'Delete user',
              text: 'Do you want to delete {name} ?',
              yes: 'Delete',
              no: 'Cancel'
            },
            ExternalUserSetting: {
              processingMessage: '{processingUser} people are being registered. (It takes up to 5 minutes)',
              update: 'Update list',
              add: 'Add external user',
              addApiUser: 'Add API user',
              headers: {
                ownership: 'Ownership',
                name: 'Name',
                mailAddress: 'Mail address',
                createdAt: 'Created'
              }
            },
            InternalUserSetting: {
              add: 'Add CA user',
              headers: {
                ownership: 'Ownership',
                name: 'Name',
                employeeNumber: 'Employee Number',
                createdAt: 'Created'
              }
            },
            UpdateDialog: {
              updateOwnership: 'Update ownership',
              grantMessage: 'Do you want to give {name} ownership?',
              removeMessage: "Are you sure you want to remove {name}'s ownership?",
              yes: 'Update',
              no: 'Cancel'
            },
            UserTable: {
              delete: 'Delete'
            }
          },
          DashboardSetting: {
            dashboardName: 'Dashboard Name',
            term: 'Period',
            startDate: 'Start date',
            day: 'day',
            save: 'Save'
          }
        },
        Dashboard: {
          search: 'Search',
          logout: 'Logout'
        },
        DeployClient: {
          title: 'Fix report',
          untaggedCampaign: 'Unset Campaign',
          untaggedAdgroup: 'Unset Category',
          loading: 'loading',
          mailAddress: 'mail address',
          message: 'Are you sure you want to fix to report?',
          messageSub: 'When you press Confirm, the notification will be sent to the email address below.',
          loadingMessage: 'Please wait while reading unset items.',
          untaggedMessage: 'Unable to fix report due to unset items.',
          runningMessage: 'Cannot fix report because it is currently running.',
          confirm: 'OK'
        },
        RegisterDialog: {
          title: 'Welcome to CA Dashboard',
          text: 'Register your name and company name.',
          namePlaceholder: 'John Smith',
          nameLabel: 'Name',
          companyNamePlaceholder: 'CyberAgent, Inc.',
          companyNameLabel: 'Company name',
          register: 'Register',
          success: 'Registered successfully.',
          enjoy: 'Enjoy CA Dashboard.'
        },
        SideNavigation: {
          deployToClients: 'Fix report',
          downloadRawdata: 'Download raw data',
          allocationExecute: 'allocation',
          setting: 'UserSetting',
          asyncJobs: 'Job list',
          integrationReport: 'ExcelReport',
          targetSetting: 'TargetSetting',
          summary: 'Summary',
          cabi_media_name: 'Media',
          campaign: 'Campaign',
          adgroup: 'Adgroup',
          creative: 'Creative',
          help: 'Help'
        }
      },
      tables: {
        AdgroupTableChart: {
          adGroupList: 'Ad Group List',
          partialDisplay: 'Partial Display',
          showAll: 'Show All',
          Total: 'Total',
          Estimation: 'Estimation',
          '7daysAverage': '7daysAverage'
        },
        CreativeTableChart: {
          media: 'Media : ',
          campaign: 'Campaign : ',
          adGroup: 'Ad Group : ',
          partialDisplay: 'Partial Display',
          showAll: 'Show All',
          Total: 'Total',
          Estimation: 'Estimation',
          '7daysAverage': '7daysAverage'
        },
        TableChart: {
          partialDisplay: 'Partial Display',
          showAll: 'Show All',
          Total: 'Total',
          Estimation: 'Estimation',
          '7daysAverage': '7daysAverage'
        },
        ToggleTableChart: {
          Total: 'Total',
          Estimation: 'Estimation',
          '7daysAverage': '7daysAverage'
        },
        CampaignTable: {
          partialDisplay: 'Partial Display',
          showAll: 'Show All',
          Total: 'Total',
          Estimation: 'Estimation',
          '7daysAverage': '7daysAverage'
        },
        AdgroupTable: {
          partialDisplay: 'Partial Display',
          showAll: 'Show All',
          Total: 'Total',
          Estimation: 'Estimation',
          '7daysAverage': '7daysAverage'
        }
      },
      FilterList: {
        filter: 'Refine',
        campaign: 'Campaign',
        adGroup: 'Ad Group',
        media: 'Media',
        selectAll: 'Select All',
        save: 'Save filter',
        yes: 'Search',
        no: 'Cancel'
      },
      FilterSave: {
        title: 'Filter Save',
        name: 'Filter Name',
        nameErrorMessage: 'The filter name is required',
        prohibitedCharactersErrorMessage: 'The filter name contains prohibited characters',
        sharingPermissions: 'Sharing Permissions',
        sharingPermissionsLabelPrivate: 'Private',
        sharingPermissionsLabelPublic: 'Public',
        sharingPermissionsWarning: 'When published, it will be published to all users who can view this dashboard',
        cancel: 'Cancel',
        save: 'Save'
      },
      FilterSaveList: {
        search: 'Search',
        selectMessage: 'Applied the selected filter'
      },
      FilterDelete: {
        title: 'Filter Delete',
        deleteMessage: 'Are you sure you want to delete this filter?',
        cancel: 'Cancel',
        delete: 'Delete'
      },
      Period: {
        yesterday: 'Yesterday',
        last7days: 'Last 7days',
        last30days: 'Last 30days',
        last60days: 'Last 60days',
        last90days: 'Last 90days',
        last120days: 'Last 120days',
        last150days: 'Last 150days',
        last180days: 'Last 180days',
        thisMonth: 'This Month',
        lastMonth: 'Last Month',
        nextMonth: 'Next Month'
      },
      targetSetting: {
        invalidCsvHeaderMessage: 'header information does not match',
        confirmMessage: 'Do you want to register target value with this setting？',
        completeMessage: 'Reflected a target setting',
        errorMessage: 'Failed to reflect a target setting',
        reflectToAllMessage: 'Do you want to reflect the target value to Total?',
        register: {
          yes: 'OK',
          no: 'CANCEL'
        }
      },
      AsyncJobs: {
        Status: {
          FINISH: 'FINISH',
          READY: 'READY',
          ERROR: 'ERROR'
        },
        JobType: {
          CLIENT_REPORT: 'Deployment to customers',
          GET_RAWDATA: 'Get Rawdata',
          GET_INTEGRATION_REPORT: 'Export Excel',
          SHARE_ASYNC_JOB_RESULT: 'Submission',
          GET_CATEGORY: 'Get Category',
          SET_CATEGORY: 'Set Category',
          RESTORE_CACHE: 'restore cache',
          ALLOCATION_EXECUTE: 'Allocation'
        },
        ShareJobType: {
          GET_INTEGRATION_REPORT: 'Submit Excel report'
        },
        JobDetail: {
          GET_RAWDATA: 'Period',
          GET_INTEGRATION_REPORT: 'Output Filename',
          ALLOCATION_EXECUTE: 'Execution name',
          SHARE_ASYNC_JOB_RESULT: 'Target Job ID',
          CLIENT_REPORT: 'End time'
        },
        Action: {
          DOWNLOAD: 'download',
          COPY: 'copy',
          RESULT: 'result'
        }
      },
      pages: {
        Common: {
          output: 'output',
          cancel: 'cancel',
          delete: 'delete',
          next: 'next',
          prev: 'previous',
          change: 'change'
        },
        Setting: {
          setting: 'UserSetting',
          dashboard: 'Dashboard',
          caMember: 'CA Users',
          member: 'External Users'
        },
        IntegrationReport: {
          title: 'ExcelReport',
          step: {
            name: 'NameSetting',
            period: 'Period',
            customColumn: 'ColumnSetting',
            filter: 'Filter',
            sort: 'Sort',
            averageDay: 'Estimation',
            target: 'Target',
            outputSummary: 'Summary',
            outputDaily: 'Daily',
            outputWeekly: 'Weekly',
            outputMonthly: 'Monthly',
            outputCreative: 'Creative'
          },
          message: {
            weekStart: 'The starting day of the week is',
            monthStart: 'The starting day is',
            confirm: {
              customColumnDelete: 'It is set in the order, is it okay to delete it?',
              output: 'Do you want to output with this setting?'
            }
          },
          name: {
            companyName: 'companyName',
            reportName: 'reportName',
            password: 'password'
          },
          customColumn: {
            dataBar: 'dataBar',
            index: 'index',
            targetIndex: 'targetIndex'
          },
          filter: 'filter',
          sort: {
            index: 'index'
          },
          averageDay: {
            default: 'default',
            date: 'dateSetting'
          },
          target: {
            all: 'ALL',
            custom: 'Custom'
          }
        },
        TargetSetting: {
          title: 'TargetSetting',
          register: 'Register',
          download: 'Download',
          upload: 'Upload',
          settingType: {
            manual: 'Manual',
            bulk: 'CSV Upload'
          },
          step: {
            selectTargetAndPeriod: 'Select target and period',
            targetSetting: 'Target setting',
            reflectToALL: 'Confirm reflection to Total'
          },
          target: {
            all: 'Total',
            media: 'Media',
            category: 'Category'
          },
          total: 'Total',
          note: 'Media and categories with no track record will not appear in the list'
        },
        Allocation: {
          title: 'Allocation',
          step: {
            period: 'Period',
            budgetLayer: 'Budget Layer setting',
            mapping: 'Mapping setting',
            cost: 'Target amount setting',
            suppressionKeyword: 'Suppression setting',
            suspensionPeriod: 'Suspension period setting',
            averageDay: 'Estimation',
            name: 'Name setting'
          },
          label: {
            outputPeriod: 'Output period',
            inputPeriod: 'Learning period',
            mappingKpi: 'Metric you want to optimize',
            mappingColumn: 'Metric mapping',
            impression: { CPA: 'impression', ROAS: 'impression' },
            click: { CPA: 'click', ROAS: 'click' },
            cost: { CPA: 'cost', ROAS: 'cost' },
            kpi: { CPA: 'cv', ROAS: 'sales' },
            filterMediaNamesSwitch: 'Filter media',
            filterMediaNames: 'Multiple selection is possible',
            target: 'target index',
            planValue: 'target amount',
            targetCostSwitch: 'Enter the amount  manually',
            suppressionKeywords: 'Multiple keywords can be entered.',
            name: 'Allocation execution name'
          },
          message: {
            inputPeriodCaption: '(Select within the range of the last 60 to 180 days of the past date)',
            recommendedOutputFromDate:
              'If the start date is in the future, we recommend within 15 days.\r\nIncreased chance of inaccuracy and error.',
            recommendedOutputPeriodLength:
              'The selection exceeds the recommended period of 62 days.\n\r\nIncreased chance of inaccuracy and error.',
            invalidOutputToDate: 'Selection must include future dates.',
            invalidInputFromDate: 'Please select within the range of the last 60 to 180 days of the past day.',
            invalidFilterMediaNames: 'Media( {mediaNames} ) does not exist. Please set it again.',
            isDeletedColumn: 'The index ( {column} ) has changed. Please set again.',
            recommendedPlanValue:
              'Considering the expected value, less than {cost} will result in loss of accuracy and more chance of error.',
            invalidPlanValue: 'Set 1 or higher.',
            invalidProhibitedChara: 'Contains prohibited characters.',
            targetCostCaption: 'The target value for {fromDate} ~ {toDate} is " {value} ".',
            suppressionKeywordDescription: {
              CAMPAIGN:
                'It is possible to suppress CPN including specified wording during learning.\r\n[Suppressible granularity]   CPN: ○    ADG: ×    AD: ×    KW: ×',
              ADGROUP:
                'It is possible to suppress ADG including specified wording during learning.\r\n[Suppressible granularity]   CPN: ×    ADG: ○    AD: ×    KW: ×'
            },
            suspensionPeriodDescription:
              'If there is no delivery record for target days in a row, it will not be included in the learning period.',
            averageDayDescription1:
              'Average value calculation days (select the number of days for which you want to calculate the average value)',
            averageDayDescription2:
              'Landing prediction calculation formula example (when the average value calculation days is 7) \n\r ((past 7 days result / 7) * remaining days) + total result up to the previous day'
          },
          target: {
            CPA: 'CPA',
            ROAS: 'ROAS'
          },
          budgetLayer: {
            CAMPAIGN: 'Campaign',
            ADGROUP: 'Ad Group'
          }
        },
        AsyncJobs: {
          title: 'Job list',
          search: 'Search',
          jobId: 'JobId',
          jobDetail: 'Detail',
          userId: 'UserId',
          jobType: 'JobType',
          status: 'Status',
          createdAt: 'StartTime'
        },
        DeployClient: {
          title: 'Fix report'
        },
        Rawdata: {
          title: 'Download raw data',
          period: 'Period'
        },
        AllocationResult: {
          allocationParams: 'Allocation Params',
          estimation: 'Estimation',
          allocation: 'Allocation',
          total: 'Total',
          param: {
            outputDate: 'Output date',
            inputDate: 'Input date',
            budgetLayer: 'Budget Layer',
            filterMediaNames: 'Filter media',
            targetCost: 'Target cost',
            planValue: 'Plan value',
            impressionColumnName: 'Impression column name',
            optimization: 'Optimization',
            clickColumnName: 'Click column name',
            costColumnName: 'Cost column name',
            kpiColumnNameCv: 'CV column name',
            kpiColumnNameRoas: 'ROAS column name',
            suppressionKeywords: 'Suppression keywords',
            filterTargetDays: 'Suspension period',
            calcAverageDayMethod: 'Calc average day method',
            averageDay: 'days average',
            day: 'day',
            name: 'Name'
          }
        }
      }
    },
    'ja-JP': {
      integration_report: {
        step: {
          report_template: 'フォーマット設定'
        },
        report_template: {
          label_horizontal: '標準',
          label_vertical: '縦型'
        }
      },
      // TODO: 以下のi18nの書き方統一させる
      charts: {
        CompareChart: {
          Total: '合計',
          Estimation: '着地予想',
          '7daysAverage': '7日平均'
        },
        TargetChart: {
          TargetIsNotSet: '目標が設定されてません'
        }
      },
      globals: {
        push: {
          allowPushText1: 'プッシュ通知を許可すると、すぐに結果を受け取れます',
          allowPushText2: '　',
          allowPushText3: '例）',
          allowPushText4: 'ローデータダウンロード完了時'
        },
        notices: {
          Notice: {
            download: 'ダウンロード',
            blank: 'No data available'
          },
          NoticeMenu: {
            notification: 'お知らせ'
          }
        },
        settings: {
          users: {
            AddDialog: {
              addNewMember: 'ユーザーの新規追加',
              mailAddress: 'メールアドレス',
              notRegisteredMessage: 'メールアドレスのドメインが登録されていません。',
              usableDomainMessage: '(※追加できるメールアドレスのドメインは {domain} です。)',
              employeeNumber: '社員番号',
              grantOwnership: 'オーナー権限を付与する',
              yes: '追加',
              no: 'キャンセル'
            },
            AddApiUserDialog: {
              addNewMember: 'APIユーザーの新規追加',
              mailAddress: 'メールアドレス',
              grantOwnership: 'オーナー権限を付与する',
              yes: '追加',
              no: 'キャンセル'
            },
            DeleteDialog: {
              deleteMember: 'ユーザーの削除',
              text: '{name} を削除しますか？',
              yes: '削除',
              no: 'キャンセル'
            },
            ExternalUserSetting: {
              processingMessage: '{processingUser} 人の登録処理を実行中です。(最大で5分ほどかかります)',
              update: 'リストを更新',
              add: '社外ユーザーを追加',
              addApiUser: 'APIユーザーを追加',
              headers: {
                ownership: 'オーナー',
                name: '名前',
                mailAddress: 'メールアドレス',
                createdAt: '作成日時'
              }
            },
            InternalUserSetting: {
              add: 'CAユーザーを追加',
              headers: {
                ownership: 'オーナー',
                name: '名前',
                employeeNumber: '社員番号',
                createdAt: '作成日時'
              }
            },
            UpdateDialog: {
              updateOwnership: 'オーナー権限の更新',
              grantMessage: '{name} にオーナー権限を付与しますか？',
              removeMessage: '{name} のオーナー権限を削除しますか？',
              yes: '更新',
              no: 'キャンセル'
            },
            UserTable: {
              delete: '削除'
            }
          },
          DashboardSetting: {
            dashboardName: 'ダッシュボード名',
            term: '期間',
            startDate: '開始日',
            day: '日',
            save: '保存'
          }
        },
        Dashboard: {
          search: '検索',
          logout: 'ログアウト'
        },
        DeployClient: {
          title: '数値確定',
          untaggedCampaign: '未振り分けキャンペーン',
          untaggedAdgroup: '未振り分けカテゴリ',
          loading: '読込中',
          mailAddress: 'メールアドレス',
          message: '数値確定をしてもよろしいですか？',
          messageSub: '確定を押すと下記メールアドレス宛に確定が通知されます。',
          loadingMessage: '未振り分けの項目を読込中のためお待ち下さい。',
          untaggedMessage: '未振り分けの項目が存在するため、数値確定を実行できません。',
          runningMessage: '現在実行中のため、数値確定を実行できません。',
          confirm: '確定'
        },
        RegisterDialog: {
          title: 'CA Dashboardへようこそ',
          text: 'お名前と会社名を登録してください。',
          namePlaceholder: '山田 太郎',
          nameLabel: 'お名前',
          companyNamePlaceholder: '株式会社サイバーエージェント',
          companyNameLabel: '会社名',
          register: '登録',
          success: '登録に成功しました',
          enjoy: 'CA Dashboardをご活用ください'
        },
        SideNavigation: {
          deployToClients: '数値確定',
          downloadRawdata: 'ローデータDL',
          allocationExecute: 'アロケーション出力',
          setting: 'ユーザー管理',
          asyncJobs: 'ジョブ一覧',
          integrationReport: 'Excel出力',
          targetSetting: '目標設定',
          summary: 'サマリー',
          cabi_media_name: 'メディア',
          campaign: 'キャンペーン',
          adgroup: 'アドグループ',
          creative: 'クリエイティブ',
          help: 'ヘルプ'
        }
      },
      tables: {
        AdgroupTableChart: {
          adGroupList: 'アドグループリスト',
          partialDisplay: '一部表示',
          showAll: 'すべて表示',
          Total: '合計',
          Estimation: '着地予想',
          '7daysAverage': '7日平均'
        },
        CreativeTableChart: {
          media: 'メディア：',
          campaign: 'キャンペーン：',
          adGroup: 'アドグループ：',
          partialDisplay: '一部表示',
          showAll: 'すべて表示',
          Total: '合計',
          Estimation: '着地予想',
          '7daysAverage': '7日平均'
        },
        TableChart: {
          partialDisplay: '一部表示',
          showAll: 'すべて表示',
          Total: '合計',
          Estimation: '着地予想',
          '7daysAverage': '7日平均'
        },
        ToggleTableChart: {
          Total: '合計',
          Estimation: '着地予想',
          '7daysAverage': '7日平均'
        },
        CampaignTable: {
          partialDisplay: '一部表示',
          showAll: 'すべて表示',
          Total: '合計',
          Estimation: '着地予想',
          '7daysAverage': '7日平均'
        },
        AdgroupTable: {
          partialDisplay: '一部表示',
          showAll: 'すべて表示',
          Total: '合計',
          Estimation: '着地予想',
          '7daysAverage': '7日平均'
        }
      },
      FilterList: {
        filter: '絞り込み',
        campaign: 'キャンペーン',
        adGroup: 'アドグループ',
        media: 'メディア',
        selectAll: '全選択',
        save: 'フィルターを保存',
        yes: '検索',
        no: 'キャンセル'
      },
      FilterSave: {
        title: 'フィルターの保存',
        name: 'フィルター名',
        nameErrorMessage: 'フィルター名は必須です',
        prohibitedCharactersErrorMessage: 'フィルター名に禁止文字が含まれています',
        sharingPermissions: '公開範囲',
        sharingPermissionsLabelPrivate: '非公開',
        sharingPermissionsLabelPublic: '公開',
        sharingPermissionsWarning: '※公開するとこのダッシュボードを閲覧可能なすべてのユーザーに公開されます',
        cancel: 'キャンセル',
        save: '保存'
      },
      FilterSaveList: {
        search: '検索',
        selectMessage: '選択したフィルターを適用しました'
      },
      FilterDelete: {
        title: 'フィルターの削除',
        deleteMessage: 'このフィルターを削除してもよろしいですか？',
        cancel: 'キャンセル',
        delete: '削除'
      },
      Period: {
        yesterday: '昨日',
        last7days: '過去7日',
        last30days: '過去30日',
        last60days: '過去60日',
        last90days: '過去90日',
        last120days: '過去120日',
        last150days: '過去150日',
        last180days: '過去180日',
        thisMonth: '当月',
        lastMonth: '前月',
        nextMonth: '来月'
      },
      targetSetting: {
        invalidCsvHeaderMessage: 'CSVヘッダー情報が一致していません',
        confirmMessage: 'この設定で目標を登録しますか？',
        completeMessage: '目標を反映しました',
        errorMessage: '目標の反映に失敗しました',
        reflectToAllMessage: '以下の数値をTotalとして登録する',
        register: {
          yes: '登録',
          no: 'キャンセル'
        }
      },
      AsyncJobs: {
        Status: {
          FINISH: 'FINISH',
          READY: 'READY',
          ERROR: 'ERROR'
        },
        JobType: {
          CLIENT_REPORT: '数値確定',
          GET_RAWDATA: 'ローデータDL',
          GET_INTEGRATION_REPORT: 'Excel出力',
          SHARE_ASYNC_JOB_RESULT: '提出',
          GET_CATEGORY: 'カテゴリ取得',
          SET_CATEGORY: 'カテゴリ設定',
          RESTORE_CACHE: 'RESTORE_CACHE',
          ALLOCATION_EXECUTE: 'アロケーション'
        },
        ShareJobType: {
          GET_INTEGRATION_REPORT: 'Excelレポート提出'
        },
        JobDetail: {
          GET_RAWDATA: '出力期間',
          GET_INTEGRATION_REPORT: '出力ファイル名',
          ALLOCATION_EXECUTE: '実行名称',
          SHARE_ASYNC_JOB_RESULT: '対象ジョブID',
          CLIENT_REPORT: '完了時間'
        },
        Action: {
          DOWNLOAD: 'ダウンロード',
          COPY: 'コピーして新規作成',
          RESULT: '結果表示'
        }
      },
      pages: {
        Common: {
          output: '出力',
          cancel: 'キャンセル',
          delete: '削除',
          next: '次へ',
          prev: '前へ',
          change: '変更'
        },
        Setting: {
          setting: 'ユーザー管理',
          dashboard: 'ダッシュボード',
          caMember: 'CAユーザー',
          member: '社外ユーザー'
        },
        IntegrationReport: {
          title: 'Excel出力',
          step: {
            name: '名称設定',
            period: '対象期間',
            customColumn: '指標設定',
            filter: '絞り込み設定',
            sort: '並び順設定',
            averageDay: '着地予測平均値設定',
            target: '目標設定',
            outputSummary: 'サマリ',
            outputDaily: 'デイリー',
            outputWeekly: 'ウィークリー',
            outputMonthly: 'マンスリー',
            outputCreative: 'クリエイティブ'
          },
          message: {
            weekStart: '開始曜日は',
            monthStart: '開始日は',
            confirm: {
              customColumnDelete: '並び順に設定されていますが、削除してもよろしいですか？',
              output: 'この設定で出力しますか？'
            }
          },
          name: {
            companyName: '会社名',
            reportName: 'レポート名',
            password: 'パスワード'
          },
          customColumn: {
            dataBar: 'データバー',
            index: '指標',
            targetIndex: '目標指標'
          },
          filter: 'フィルター',
          sort: {
            index: '指標'
          },
          averageDay: {
            default: 'デフォルト',
            date: '日付設定'
          },
          target: {
            all: 'ALL',
            custom: 'カスタム'
          }
        },
        TargetSetting: {
          title: '目標設定',
          register: '登録',
          download: 'ダウンロード',
          upload: 'アップロード',
          settingType: {
            manual: '手動',
            bulk: 'CSVアップロード'
          },
          step: {
            selectTargetAndPeriod: '目標 / 期間選択',
            targetSetting: '目標値設定',
            reflectToALL: 'Totalへの登録'
          },
          target: {
            all: 'Total',
            media: 'メディア',
            category: 'カテゴリ'
          },
          total: '合計',
          note: '実績がないメディアやカテゴリは一覧に表示されません'
        },
        Allocation: {
          title: 'アロケーション',
          step: {
            period: '期間',
            budgetLayer: '学習粒度の設定',
            mapping: '指標設定',
            cost: '目標金額の設定',
            suppressionKeyword: '抑制設定',
            suspensionPeriod: '停止期間の判定',
            averageDay: '着地予測平均値設定',
            name: '名称設定'
          },
          label: {
            outputPeriod: '予測期間',
            inputPeriod: '学習期間',
            mappingKpi: '最適化したい指標',
            mappingColumn: '指標のマッピング',
            impression: { CPA: 'impression指標', ROAS: 'impression指標' },
            click: { CPA: 'click指標', ROAS: 'click指標' },
            cost: { CPA: 'cost指標', ROAS: 'cost指標' },
            kpi: { CPA: 'cv指標', ROAS: '売上指標' },
            filterMediaNamesSwitch: 'メディアの絞り込み',
            filterMediaNames: '複数選択可能です',
            target: '目標指標',
            planValue: '目標金額',
            targetCostSwitch: '金額を手動入力',
            suppressionKeywords: '複数入力可能です',
            name: 'アロケーション実行名称'
          },
          message: {
            inputPeriodCaption: '(過去日の直近60〜180日の範囲で選択)',
            recommendedOutputFromDate:
              '開始日が未来日の場合、15日以内が推奨です。\r\n精度低下やエラーの可能性が高くなります。',
            recommendedOutputPeriodLength:
              '選択範囲が推奨期間の62日を超えています。\r\n精度低下やエラーの可能性が高くなります。',
            invalidOutputToDate: '選択範囲に未来日を含める必要があります。',
            invalidInputFromDate: '過去日の直近60〜180日の範囲で選択してください。',
            invalidFilterMediaNames: 'メディア( {mediaNames} )は存在しません。再度設定してください。',
            isDeletedColumn: '指標( {column} )は変更されています。再度設定してください。',
            recommendedPlanValue:
              '{cost} 以上を推奨しています。\r\n精度低下やエラーの可能性が高くなります。\r\n※対象期間のコストの着地予想から算出',
            invalidPlanValue: '1 以上を設定してください。',
            invalidProhibitedChara: '禁止文字が含まれています。',
            targetCostCaption: 'の {fromDate} ~ {toDate} の目標値は「 {value} 」です。',
            suppressionKeywordDescription: {
              CAMPAIGN:
                '指定された文言を含む CPN を、学習の際に抑制することが可能です。\r\n[ 抑制できる粒度 ]   CPN: ○    ADG: ×    AD: ×    KW: ×',
              ADGROUP:
                '指定された文言を含む ADG を、学習の際に抑制することが可能です。\r\n[ 抑制できる粒度 ]   CPN: ×    ADG: ○    AD: ×    KW: ×'
            },
            suspensionPeriodDescription: '連続して実績がない場合は、学習期間に含みません。',
            averageDayDescription1: '平均値算出日数（平均値を算出したい日数を選択）',
            averageDayDescription2:
              '※着地予測計算式イメージ（平均値算出日数が7の場合）\n\r((過去7日間実績/7)*残り日数)+前日までの実績合計'
          },
          target: {
            CPA: 'CPA',
            ROAS: 'ROAS'
          },
          budgetLayer: {
            CAMPAIGN: 'キャンペーン',
            ADGROUP: 'アドグループ'
          }
        },
        AsyncJobs: {
          title: 'ジョブ一覧',
          search: '検索',
          jobId: 'ジョブID',
          jobDetail: '内容',
          userId: 'ユーザーID',
          jobType: 'ジョブ名',
          status: 'ステータス',
          createdAt: '開始時間'
        },
        DeployClient: {
          title: '数値確定'
        },
        Rawdata: {
          title: 'ローデータDL',
          period: '対象期間'
        },
        AllocationResult: {
          allocationParams: 'アロケーション実行情報',
          estimation: '着地予想',
          allocation: 'シミュレーション結果',
          total: '合計',
          param: {
            outputDate: '予測したい期間',
            inputDate: '学習に用いた期間',
            budgetLayer: '学習粒度',
            filterMediaNames: 'メディアの絞り込み',
            targetCost: '目標金額指標',
            planValue: '目標金額',
            impressionColumnName: 'インプレッション指標',
            optimization: '最適化したい指標',
            clickColumnName: 'クリック指標',
            costColumnName: 'コスト指標',
            kpiColumnNameCv: 'CV指標',
            kpiColumnNameRoas: '売上指標',
            suppressionKeywords: '抑制設定キーワード\n（入力値は改行区切りで表示されます）',
            filterTargetDays: '停止期間の判定',
            calcAverageDayMethod: '着地予想の算出方法',
            average: '日間平均',
            day: '日',
            name: '名称設定'
          }
        }
      }
    }
  }
})
