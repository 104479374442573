<template>
  <Dialog :model-value="isShow" max-width="500" @click:outside="$emit('update:isShow', false)">
    <Card>
      <template #title>
        <span>{{ $t('target.kpi_index_setting.register_message') }}</span>
      </template>
      <template #actions>
        <div class="spacer" />
        <Button variant="text" @click="$emit('update:isShow', false)">
          <span>{{ $t('common.button.cancel') }}</span>
        </Button>
        <Button variant="elevated" @click="registerKpi(), $emit('update:isShow', false)">
          <span>{{ $t('common.button.register') }}</span>
        </Button>
      </template>
    </Card>
  </Dialog>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { updateKpiAllSetting, updateKpiCategorySettings, updateKpiMediaSettings } from '@/application/target/usecase'
import Button from '@/components/atoms/Button/Button.vue'
import Card from '@/components/atoms/Card/Card.vue'
import Dialog from '@/components/atoms/Dialog/Dialog.vue'
import { useCondition } from '@/components/hooks/condition'
import { useMutation } from '@/components/hooks/query'
import type { Target } from '@/domain/model/target/types'
import type { KpiItems } from '@/pages/TargetSetting.vue'
import { SET_MESSAGE } from '@/vuex/types'

type Props = {
  isShow: boolean
  target: Target
  kpiItems: KpiItems
}

const props = defineProps<Props>()
const emit = defineEmits<{
  'update:isShow': [v: boolean]
  onSuccess: []
}>()

const store = useStore()
const { t } = useI18n()
const { condition } = await useCondition()

const kpiParams = ref({ dashboard_id: condition.dashboardId.toString() })

const { mutate: updateKpiAllSettingMutate } = useMutation(updateKpiAllSetting)(kpiParams)
const { mutate: updateKpiMediaSettingsMutate } = useMutation(updateKpiMediaSettings)(kpiParams)
const { mutate: updateKpiCategorySettingsMutate } = useMutation(updateKpiCategorySettings)(kpiParams)

const registerKpi = () => {
  switch (props.target) {
    case 'all':
      props.kpiItems.all?.[0] &&
        updateKpiAllSettingMutate(
          { kpi_all_settings: props.kpiItems.all[0] },
          {
            onSuccess: () => {
              // TODO: piniaに移行する
              store.commit(SET_MESSAGE, {
                type: 'green-darken-2',
                text: t('target.kpi_index_setting.register_message_success')
              })
              emit('onSuccess')
            },
            onError: () => {
              // TODO: piniaに移行する
              store.commit(SET_MESSAGE, {
                type: 'error',
                text: t('target.kpi_index_setting.register_message_error')
              })
            }
          }
        )
      break
    case 'media':
      props.kpiItems.media &&
        updateKpiMediaSettingsMutate(
          {
            kpi_media_settings: props.kpiItems.media
              .filter(m => m.isActive)
              .map(m => ({
                cabi_media_name: m.cabi_media_name,
                cost_index: m.cost_index,
                kpi_index: m.kpi_index,
                criteria: m.criteria
              }))
          },
          {
            onSuccess: () => {
              // TODO: piniaに移行する
              store.commit(SET_MESSAGE, {
                type: 'green-darken-2',
                text: t('target.kpi_index_setting.register_message_success')
              })
              emit('onSuccess')
            },
            onError: () => {
              // TODO: piniaに移行する
              store.commit(SET_MESSAGE, {
                type: 'error',
                text: t('target.kpi_index_setting.register_message_error')
              })
            }
          }
        )
      break
    case 'category':
      props.kpiItems.category &&
        updateKpiCategorySettingsMutate(
          {
            kpi_category_settings: props.kpiItems.category
              .filter(c => c.isActive)
              .map(c => ({
                category_key: c.category_key,
                category_value: c.category_value,
                cost_index: c.cost_index,
                kpi_index: c.kpi_index,
                criteria: c.criteria
              }))
          },
          {
            onSuccess: () => {
              // TODO: piniaに移行する
              store.commit(SET_MESSAGE, {
                type: 'green-darken-2',
                text: t('target.kpi_index_setting.register_message_success')
              })
              emit('onSuccess')
            },
            onError: () => {
              // TODO: piniaに移行する
              store.commit(SET_MESSAGE, {
                type: 'error',
                text: t('target.kpi_index_setting.register_message_error')
              })
            }
          }
        )
      break
  }
}
</script>
