import App from '@/App.vue'
import routes from '@/routes'
import store from '@/vuex/store'
import { createApp } from 'vue'
import VueGtag from 'vue-gtag'
import { createRouter, createWebHashHistory } from 'vue-router'
import vuetify from '@/plugins/vuetify'
import { Quasar } from 'quasar'
import quasarLangJa from 'quasar/lang/ja'
import i18n from '@/i18n/i18n'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { createPinia } from 'pinia'

import '@quasar/extras/material-icons/material-icons.css'

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const app = createApp(App)
app.use(router)
app.use(vuetify)
app.use(Quasar, {
  lang: quasarLangJa,
  config: {
    brand: {
      primary: '#82be28',
      error: '#cd4036'
    }
  }
})
app.use(store)
app.use(createPinia())
app.use(VueQueryPlugin)
app.use(i18n)
app.use(VueGtag, { config: { id: 'UA-123253830-1' } }, router)

app.mount('#app')
